.social-icons {
  margin: 10px;
}

.phone-icon {
  margin-right: 10px;
}

.secondary-text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
