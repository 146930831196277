.title-content {
  position: relative;
}

.section-title {
  margin-bottom: 60px;
}

.card-margin {
  margin-bottom: 30px;
}

.secondary-text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
