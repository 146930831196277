.book-now-btn {
  background-color: #da3324 !important;
  color: white !important;
  padding: 14px 28px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  padding-right: 30px;
}

.book-now-btn:hover {
  background-color:  #b02a1e !important;
}

.arrow-margin {
  margin-left: 10px;
}

.secondary-text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
