@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.first-banner {
  background-color: #007bff;
  color: #ffffff;
  padding: 30px 0;
  text-align: center;
  position: relative;
}

.first-banner-content {
  max-width: 1200px;
  margin: 0 auto;
}

.first-banner h1 {
  font-size: 3em;
  margin-bottom: 10px;
  font-family: "Pacifico", cursive;
}

.first-banner .highlight {
  background: linear-gradient(360deg, transparent 10%, #ff0000 50%);
  font-size: 3em;
  font-weight: bold;
  font-family: "Pacifico", cursive;
}


@media (max-width: 450px) {
  .first-banner h1 {
    font-size: 2em;
  }
  .first-banner .highlight {
    font-size: 2em;
  }
}

.second-banner {
  background-color: #da3324;
  color: #ffffff;
  padding: 30px 0;
  text-align: center;
  position: relative;
}

.second-banner-content {
  font-size: 3em;
  margin-bottom: 10px;
  font-family: "Pacifico", cursive;
}

.hero-container {
  max-width: 100%;
  display: flex;
  flex-direction: row; /* Display elements side by side by default */
  align-items: center;
  justify-content: center;
  padding: 30px;
  
}

.logo1 {
  border: 3px solid black;
  max-width: 25%; /* Make the image responsive, it will scale down with its container */
}

.message {
  font-size: 1.5em;
  font-family:monospace;
  max-width: 50%;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    text-align: center; /* Center-align elements vertically and horizontally */
  }

  .logo1{
   max-width: 75%;
  }

  .message {
    margin-left: 0px;
    margin-top: 10px; /* Add space between the image and the message on smaller screens */
    max-width: 100%; /* Make the message responsive, it will scale down with its container */
  }
}

@media (max-width: 500px) {
  .message{
    font-size: 1.25em;
  }
}
.logo2 {
  border: 5px solid black;
}
