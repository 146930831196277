.carousel-images {
  width: 100%;
  height: auto;
}

.left{
 border-left: 5px solid black;
 border-top: 5px solid black;
 border-bottom: 5px solid black;
 border-right: 2.5px solid white;
}

.right{
  border-left: 2.5px solid white;
  border-right: 5px solid black;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
 }

.max-width-container {
  max-width: 1370px;
  margin: 0 auto;
}

.carousel-container {
  position: relative;
}

.slick-dots li button {
  margin-top: 10px;
  width: 15px;
  height: 15px;
}

.slick-dots li button:before {
  margin-top: 27px;
  background-color: black;
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
}

.secondary-text-carousel {
  font-size: 2em;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: center !important;
  
}

.arrow-left,
.arrow-right {
  color: black;
  cursor: pointer;
  z-index: 2;
  margin-left: 40px;
  margin-right: 40px;
  min-width: 20px;
}

@media (max-width: 500px){
  .arrow-left,
.arrow-right {
  display: none;
}
}

@media (max-width: 768px) {
  .carousel-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .left{
    border: 5px solid black;
  }
  .right{
    border: 5px solid black;
  }
}
