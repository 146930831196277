body {
  margin-top: 20px;
}
.pricing-content {
  position: relative;
}
.pricing_design {
  position: relative;
  margin: 0px 15px;
}
.pricing_design .single-pricing {
  background: #007bff;
  padding: 60px 40px;
  border-radius: 30px;
  box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.2);
  position: relative;
  z-index: 1;
}
.pricing_design .single-pricing:before {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 18px 18px 190px 18px;
  border: 1px solid #eee;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.price-head h2 {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 600;
}
.price-head h1 {
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 5px;
}

.single-pricing ul {
  list-style: none;
  margin-top: 30px;
  margin-left: -40px;
}
.single-pricing ul li {
  line-height: 36px;
}

.section-title {
  margin-bottom: 60px;
}

.section-title h2 {
  font-size: 45px;
  font-weight: 600;
  margin-top: 0;
  position: relative;
  text-transform: capitalize;
}

.margin-bottom {
  margin-bottom: 25px;
}

.secondary-text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
