/* Navigation.css */

.navbar-with-border {
    border-bottom: 10px solid #007bff; /* Adjust the color and thickness as needed */
}

.nav-link:hover{
    color: #0057b3 !important;
}

/* navigation.css */
.active {
    color: #007bff !important; /* Set the text color to blue for active links */
}

@media (max-width: 450px) {
    .nav {
      flex-direction: column; /* Change flex direction to column for small screens */
      align-items: center; /* Center-align items vertically */
      text-align: center; /* Center-align text horizontally */
      justify-content: center;
    }
  
    /* Style for the button when it's under the links */
    .book-now-btn {
      margin-top: 10px; /* Add some top margin to separate it from the links */
    }
  }
  
