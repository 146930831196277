body {
  margin: 0;
  padding: 0;
  background-image: url(../public/pictures/bubblesOpaque.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
}
